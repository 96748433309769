import Swiper from 'swiper/swiper-bundle.js';
import Loadeer from "loadeer";

import 'swiper/swiper.scss';

export default class project {
  constructor() {
    this.init();
  }

  init() {

    //// Swiper Portfolio

    var swiper = new Swiper('.swiper', {

      slidesPerView: 1,
      speed: 0,
      effect: 'fade',
      loop: true,
      keyboard: {
        enabled: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

    });

  }

}