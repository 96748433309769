import "./_lib/modernizr";
import "../scss/application.scss";

import Website from "./_modules/website";
import Home from "./_modules/home";
import Project from "./_modules/project";
import Infos from "./_modules/infos";

new Website();
new Home();
new Infos();
new Project();