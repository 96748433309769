export default class Infos {
  constructor() {
    this.init();
  }

  init() {
    // const infosHeader = document.querySelector(".infos__header");
    // const infosHeaderItem = document.querySelector(".infos__header__item--first");
    // if(typeof(infosHeader) != 'undefined' && infosHeader != null){

    //   document.addEventListener('scroll', function(e) {
    //    if(window.scrollY > 50){
    //     infosHeaderItem.classList.add("infos__header__item--hidden");
    //    }
    //    else{
    //     infosHeaderItem.classList.remove("infos__header__item--hidden");
    //    }
    //   });

    // }

    const infosCopyrightC = document.querySelector(".infos__copyright__c");
    const infosCopyrightInfos = document.querySelector(".infos__copyright__infos");
    const infosCopyright = document.querySelector(".infos__copyright:hover");

  //   infosCopyrightC.addEventListener("mouseenter", function() {
  //    infosCopyrightInfos.classList.remove("infos__copyright__infos--hidden");
  //   });
  //  infosCopyrightInfos.addEventListener("mouseout", function() {
  //     setTimeout(function () {
  //       if (infosCopyright != null) {
  //       } else {
  //        infosCopyrightInfos.classList.add("infos__copyright__infos--hidden");
  //       }
  //     }, 200);
  //   });

  }
  
}
