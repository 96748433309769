import Swiper from 'swiper/swiper-bundle.js';

import 'swiper/swiper.scss';

export default class Home {
  constructor() {
    this.init();
  }

  init() {

    ///// Switch 

    const index = document.querySelector(".index");
    const grid = document.querySelector(".grid");
    const button = document.querySelector(".button-switch");
    var switchDisplay = true;

    if (typeof (index) != 'undefined' && index != null) {

      button.onclick = function () {
        index.classList.toggle('hide');
        grid.classList.toggle('hide');
        if (switchDisplay == true) {
          button.innerHTML = "images";
          switchDisplay = false;
        }
        else if (switchDisplay == false) {
          button.innerHTML = "index";
          switchDisplay = true;
        }
      };

    }
    ///////////////////////
    ////// Overlapping text
    ///////////////////////

    /// Get if element are overlapping

    const thumnbails = document.querySelectorAll(".grid__thumbnail");
    const header = document.querySelector("header");

    const isOverlapping = (e1, e2) => {
      if (e1.length && e1.length > 1) {
        e1 = e1[0];
      }
      if (e2.length && e2.length > 1){
        e2 = e2[0];
      }
      const rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
      const rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;
      
  
      let overlap = false;
     
      if (rect1 && rect2) {
        overlap = !(
          rect1.right < rect2.left || 
          rect1.left > rect2.right || 
          rect1.bottom < rect2.top || 
          rect1.top > rect2.bottom
        );
        return overlap;  
      }
    
      console.warn('Please provide valid HTMLElement object');
      return overlap;
    }

    document.addEventListener('scroll', function (e) {

      thumnbails.forEach(element => {

        if(isOverlapping(element, header)){
          element.setAttribute("style", "opacity: 0.2");
        }
        else{
          element.setAttribute("style", "opacity: 1");
        }
        
      });

    });

  }

}