import Loadeer from "loadeer";

export default class Website {
  constructor() {
    this.init()
  }

  init() {

    //// Lazy loading

    const loadeer = new Loadeer();
    loadeer.observe();

    //// Definie right height on mobile

    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()

  }

}
